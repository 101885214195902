// TODO: Replace on Skyweavers S3
import { data as lore, PRISMS } from "data/lore"

export const VIDEO_PREFIX = `https://56utils.s3.ca-central-1.amazonaws.com/how-to-play/Skyvideos/`
export const IMAGE_PREFIX = `/images/how-to-play/`

export const data = {
	landing: {
		title: "How To Play",
		text: "Welcome to Skyweaver! This page is a guide through many of Skyweaver’s most exciting aspects, mechanics and gameplay, designed to help you understand more about the game and get started on your own journey through Sky!",
		video: { url: `${VIDEO_PREFIX}wide-landing.mp4`, placeholder: `${IMAGE_PREFIX}landing-placeholder.jpg` }
	},
	cards: {
		subtitle: "Cards",
		title: "SUMMON MIGHTY UNITS AND WEAVE SPELLS",
		mobileTitle: "SUMMON MIGHTY UNITS AND <br/> WEAVE SPELLS",
		items: [
			{
				title: "Unit Cards",
				image: { url: "/images/how-to-play/UnitCard.png", alt: "Skyweaver Unit Card" },
				text: "Units are your allies in battle. Units will remain in play until they run out of health or are removed by some other effect. Units can’t attack the same turn that they are played. They enter play “sleeping” and need a turn to get ready to attack.",
				points: [
					{
						position: [6, 6],
						title: "MANA COST",
						text: "The amount of Mana needed to play this Unit from your hand."
					},
					{
						position: [60, 45],
						title: "ATTACHED SPELL",
						text: "Units can have Spells attached to them. These have their own mana costs, and can be cast during your turn like any regular Spell, even the turn you summon the Unit holding them."
					},
					{
						position: [33, 60],
						title: "TRAITS",
						text: "Units can have Traits, special abilities that affect how the Unit functions in combat, by making them harder to attack, or increasing or reducing damage."
					},
					{
						position: [15, 70],
						title: "EFFECTS",
						text: "Some Units have one or more special Effects. These can be triggered effects like Summon and Death, or entirely unique abilities."
					},
					{
						position: [24, 90],
						title: "POWER",
						text: "Shows how much damage it will do when attacking or being attacked by another Unit or Hero."
					},
					{
						position: [68, 90],
						title: "HEALTH",
						text: `How much damage the Unit can take before being destroyed. Damage is permanent, so once a Unit loses health, it won’t regain it unless it leaves play or gains health via an effect.`
					},
					{
						position: [47, 97],
						title: "PRISM SYMBOL",
						text: "Shows the Prism the card is a part of. Avatar of Light is a part of the Heart Prism. If a card has no Prism symbol, Like Zomboid or Zap, that means that it is a “token” card, and can only be created by other cards."
					},
				]
			},
			{
				title: "Spell Cards",
				image: { url: "/images/how-to-play/SpellCard.png", alt: "Skyweaver Spell Card" },
				text: `Spells provide immediate one-use effects. Spells can often allow you to damage or destroy your opponent's Units, or strengthen your own.`,
				points: [
					{
						position: [6, 6],
						title: "COST",
						text: "The amount of Mana needed to play the Spell."
					},
					{
						position: [33, 60],
						title: "TRAITS",
						text: "Like Units, Spells can have traits, which give them additional effects when played, such as increasing your Hero’s attack or draining health from enemies."
					},
					{
						position: [20, 70],
						title: "EFFECT",
						text: "The effect the Spell will immediately apply when used. For example, the Scorch Spell deals 2 damage to any character you choose."
					},
					{
						position: [47, 97],
						title: "PRISM SYMBOL",
						text: "Shows the Prism the card belongs to."
					}
				]
			},

		]
	},
	battling: {
		subtitle: "Battling",
		title: "Take Direct Command of Battle",
		mobileTitle: "Take Direct Command <br/> of Battle",
		text: "In Skyweaver, you take center stage! Your Hero leads your Units into battle, fighting directly against enemies and helping to change the tide of combat! Defeat the enemy Hero to claim victory.",
		video: { url: `${VIDEO_PREFIX}hero-attack.mp4` }
	},
	spells: {
		subtitle: "Spells",
		title: "Cast Unique Attached Spells",
		text: "Your Units and Hero can carry their own Spell cards, allowing for amazing combinations and diverse strategies. Play Units and use their Spells immediately, or save them for a tactical advantage later. ",
		video: { url: `${VIDEO_PREFIX}attached-spell.mp4` }
	},
	traits: {
		subtitle: "Unit Traits",
		title: "Take Advantage of Unique Traits",
		mobileTitle: "Take Advantage of <br/> Unique Traits",
		text: "Within Skyweaver, there are six Unique Traits that Units can possess, each has a unique and potent effect on how combat between Units and Heroes is conducted.",
		items: [
			{
				title: "guard",
				text: "Units with Guard have a shield shaped frame, and protect your Hero from attacks, making them an indispensable frontline defense.",
				video: { url: `${VIDEO_PREFIX}guard.mp4` },
				image: { url: `${IMAGE_PREFIX}traits/gaurd.png` }
			},
			{
				title: "stealth",
				text: "Units with Stealth have a hazy aura, and cannot be attacked unless their Hero has been damaged by a Unit or Spell in the same turn, making them tricky to deal with. ",
				video: {
					url: `${VIDEO_PREFIX}stealth.mp4`,
				},
				image: { url: `${IMAGE_PREFIX}traits/stealth.png` }
			},
			{
				title: "banner",
				text: "Units with Banner have a bright glow. Each Unit you control with Banner gives your Hero +1 power, letting them dominate the field of battle!",
				video: { url: `${VIDEO_PREFIX}banner.mp4` },
				image: { url: `${IMAGE_PREFIX}traits/banner.png` }
			},
			{
				title: "armor",
				text: "Units with Armor have a shield around their health, and take one less damage from everything, making them very durable and challenging to defeat!",
				video: { url: `${VIDEO_PREFIX}armor.mp4` },
				image: { url: `${IMAGE_PREFIX}traits/armor.png` }
			},
			{
				title: "lifesteal",
				text: "Units with Lifesteal have a pink aura around their power. When a Unit with Lifesteal damages the enemy Hero, your Hero will gain that much health, making them an invaluable means of keeping your Hero in the fight!",
				video: { url: `${VIDEO_PREFIX}lifesteal.mp4` },
				image: { url: `${IMAGE_PREFIX}traits/lifesteal.png` }
			},
			{
				title: "wither",
				text: "Units with Wither have a purple aura around their power. When a Unit with Wither damages a Unit, it will reduce that Unit's power as well as its health, making Wither Units a fantastic tool both offensively and defensively.",
				video: { url: `${VIDEO_PREFIX}wither.mp4` },
				image: { url: `${IMAGE_PREFIX}traits/wither.png` }
			},
		]
	},
	traitsSpells: {
		subtitle: "Spell Traits",
		title: "Spells Can Have Traits too",
		text: "Spells can also have traits, giving them special abilities, just like Units, that can quickly swing a game in your favor!",
		video: { url: `${VIDEO_PREFIX}spell-traits.mp4` }
	},
	effects: {
		subtitle: "Effects",
		title: "Wield Powerful Abilities",
		text: "Units in Skyweaver can also have powerful abilites that activate when certain conditions are met. Understanding how best to utilize these powers is key in achieving victory! ",
		items: [
			{
				title: "play",
				text: "When a Unit with a Play effect is played from hand, its Play effect will activate, similar to a Spell, often immediately changing the tide of battle. A Unit’s Play effect will not activate if it enters play in another way though, so watch out!",
				video: { url: `${VIDEO_PREFIX}play.mp4` },
				image: { url: `${IMAGE_PREFIX}effects/play.png` }
			},
			{
				title: "summon",
				text: "When a Unit with a Summon effect enters play, its Summon effect will activate. Units’ Summon effects can activate even when they enter play from places other than the hand, allowing for powerful combos!",
				video: { url: `${VIDEO_PREFIX}summon.mp4` },
				image: { url: `${IMAGE_PREFIX}effects/summon.png` }
			},
			{
				title: "death",
				text: "When a Unit with a Death effect dies and enters the Graveyard, its Death effect will activate. Death effects can be great means of setting up defenses and traps for your opponents to fall into.",
				video: { url: `${VIDEO_PREFIX}death.mp4` },
				image: { url: `${IMAGE_PREFIX}effects/death.png` }
			},
			{
				title: "inspire",
				text: "A Unit’s Inspire effect will trigger after you play a card that matches its Inspire effect. Inspire Effects can trigger multiple times per turn, allowing for some incredible combos!",
				video: { url: `${VIDEO_PREFIX}inspire.mp4` },
				image: { url: `${IMAGE_PREFIX}effects/inspire.png` }
			},
			{
				title: "glory",
				text: "When a Unit with a Glory effect attacks and damages the enemy Hero, its Glory effect will activate! Glory effects can quickly snowball and give their user a huge advantage, so watch out!",
				video: { url: `${VIDEO_PREFIX}glory.mp4` },
				image: { url: `${IMAGE_PREFIX}effects/glory.png` }
			},
			{
				title: "sunset",
				text: "A Unit’s Sunset effect will trigger at the end of its player's turn. The fact that Sunset effects are both reliable and repeatable makes them a serious threat.",
				video: { url: `${VIDEO_PREFIX}sunset.mp4` },
				image: { url: `${IMAGE_PREFIX}effects/sunset.png` }
			},
			{
				title: "sunrise",
				text: "A Unit’s Sunrise effect will trigger at the start of its player's turn. Sunrise effects can be tricky to make work, as the Unit needs to survive for a turn to get the effect to work, but the rewards are often worth it! ",
				video: { url: `${VIDEO_PREFIX}sunrise.mp4` },
				image: { url: `${IMAGE_PREFIX}effects/sunrise.png` }
			},
		]
	},

	////////////////

	enchants: {
		subtitle: "Enchants",
		title: "ENCHANT YOUR ALLIES AND FOES",
		text: "Within Skyweaver, there are 16 unique enchantments that can be attached to Heroes and Units to empower or weaken them.",
		items: [
			{
				title: "roots",
				text: "A Unit with Roots attached cannot attack. You can remove Roots by paying three mana.",
				video: { url: `${VIDEO_PREFIX}roots.mp4` },
				image: { url: `${IMAGE_PREFIX}enchantments/roots.png` }
			},
			{
				title: "anima",
				text: "When Anima is removed from a Unit, or overwritten by a new Enchant or Spell, it will give that Unit +2 power and health. You can remove Anima by paying four mana.",
				video: { url: `${VIDEO_PREFIX}summon.mp4` },
				image: { url: `${IMAGE_PREFIX}enchantments/anima.png` }
			},
			{
				title: "frostbite",
				text: "A Unit with Frostbite attached takes two additional damage from everything, making it very vulnerable. Frostbite can be removed by paying four mana ",
				video: { url: `${VIDEO_PREFIX}frostbite.mp4` },
				image: { url: `${IMAGE_PREFIX}enchantments/frostbite.png` }
			},
			{
				title: "vapors",
				text: "When a Unit’s attached Vapors is removed from a Unit, or overwritten by a new Enchant or Spell, its Hero will gain +2 health and draw a card.",
				video: { url: `${VIDEO_PREFIX}vapors.mp4` },
				image: { url: `${IMAGE_PREFIX}enchantments/vapors.png` }
			},
			{
				title: "dazed",
				text: "A Unit with Dazed attached will be put to sleep at the start of its Player’s turn, and be unable to attack that turn, even if Dazed is later removed, making Dazed a reliable defensive option. Dazed can be removed by paying one mana. ",
				video: { url: `${VIDEO_PREFIX}dazed.mp4` },
				image: { url: `${IMAGE_PREFIX}enchantments/dazed.png` }
			},
			{
				title: "barrier",
				text: "The first time a Unit with Barrier attached would take damage from a non-combat source, like a Spell or Unit effect, the damage will be prevented, and its Barrier will be Dusted (removed from the game).",
				video: { url: `${VIDEO_PREFIX}barrier.mp4` },
				image: { url: `${IMAGE_PREFIX}enchantments/barrier.png` }
			},
			{
				title: "blind",
				text: "A Unit with Blind attached can only attack its right most enemy, severely limiting its options in combat. Blind can be removed by paying 5 mana.",
				video: { url: `${VIDEO_PREFIX}blind.mp4` },
				image: { url: `${IMAGE_PREFIX}enchantments/blind.png` }
			},
			{
				title: "shield",
				text: "The first time a Unit with Shield attached would take combat damage from a Hero or Unit attack, the damage will be prevented, and its Shield will be Dusted.",
				video: { url: `${VIDEO_PREFIX}shield.mp4` },
				image: { url: `${IMAGE_PREFIX}enchantments/shield.png` }
			},
			{
				title: "flames",
				text: "A Unit with Flames attached will take 2 damage at the start of its Player’s turn, potentially destroying it before it can counterattack! Flames can be removed by paying two mana.",
				video: { url: `${VIDEO_PREFIX}flames.mp4` },
				image: { url: `${IMAGE_PREFIX}enchantments/flames.png` }
			},
			{
				title: "fury",
				text: "When a Unit with Fury attached attacks and damages the enemy Hero, it will gain +1 power and health, its player will draw a 1 cost card, and then the Fury will be Dusted. ",
				video: { url: `${VIDEO_PREFIX}fury.mp4` },
				image: { url: `${IMAGE_PREFIX}enchantments/fury.png` }
			},
			{
				title: "chains",
				text: "A Unit with Chains attached has all its Traits, like Armor and Guard, disabled. Chains is a very flexible enchant with many applications, like breaking through Guard Units, revealing Stealth Units, and breaking Armor. ",
				video: { url: `${VIDEO_PREFIX}chains.mp4` },
				image: { url: `${IMAGE_PREFIX}enchantments/chains.png` }
			},
			{
				title: "lead",
				text: "While attached to a Unit, Lead cannot be removed or replaced by other attached Spells or enchants, and also prevents the Unit it is attached to from being Dusted. This makes Lead a valuable means of protecting your Death effect Units and ensuring their Death effects can activate.",
				video: { url: `${VIDEO_PREFIX}lead.mp4` },
				image: { url: `${IMAGE_PREFIX}enchantments/lead.png` }
			},
			{
				title: "hex",
				text: "A Unit with Hex attached will take 6 damage at the end of its player’s turn, and the Hex will be Dusted. While this does give the Unit’s player a chance to safely remove Hex first, it costs six mana to do so, making it an expensive task. ",
				video: { url: `${VIDEO_PREFIX}hex.mp4` },
				image: { url: `${IMAGE_PREFIX}enchantments/hex.png` }
			},
			{
				title: "fate",
				text: "When a Unit with Fate attached dies, its player will draw a card of that Unit’s element. With proper planning, Fate can be an incredible means of drawing the card you need, when you need it.",
				video: { url: `${VIDEO_PREFIX}fate.mp4` },
				image: { url: `${IMAGE_PREFIX}enchantments/fate.png` }
			},
			{
				title: "silence",
				text: "A Unit with Silence attached loses all its abilites, like Death and Inspire, but not Traits like Banner, Armor, Etc. Silence is most often used to shut down dangerous enemy Death effects.",
				video: { url: `${VIDEO_PREFIX}silence.mp4` },
				image: { url: `${IMAGE_PREFIX}enchantments/silence.jpg` }
			},
			{
				title: "shroud",
				text: "A Unit with Shroud attached cannot be targeted by any of the enemy player’s Spells or Play effects, though it can still be attacked. However, Shroud only lasts for one turn before being dusted, making it a powerful temporary defense.  ",
				video: { url: `${VIDEO_PREFIX}shroud.mp4` },
				image: { url: `${IMAGE_PREFIX}enchantments/shroud.png` }
			},
		]
	},
	prisms: {
		subtitle: "Prisms",
		title: "CHOOSE YOUR PRISMS",
		text: "The cards your deck uses are defined by the Hero you choose, and their Prisms. You can combine any two Prisms in the game together, but can’t combine more than two at once, so choosing the Prisms that best suit your deck and playstyle is title!",
		// EXTEND PRSIMS FROM LORE PAGE
		items: lore.prisms.content.map(prism => {
			return {
				title: prism.name,
				text: prism.text,
				image: { url: prism.thumbnail },
				contentImage: { url: prism.thumbnail }
			}
		})
	},
	heroes: {
		subtitle: "HEROES",
		title: "CHOOSE YOUR HEROES",
		text: "The cards your deck uses are defined by the Hero you choose, and their Prisms. You can combine any two Prisms in the game together, but can’t combine more than two at once, so choosing the Prisms that best suit your deck and playstyle is title!",
		items: lore.heroes.content.map(hero => {
			return {
				title: hero.name,
				text: hero.howToPlayText,
				image: { url: hero.imageThumbnail },
				contentImage: { url: hero.heroImg },
				prism: PRISMS.find(item => item.title === hero.prism)
			}
		})
	},
	conjureCards: {
		subtitle: "Conjuring",
		title: "Conjure Cards to Aid you in battle",
		mobileTitle: "Conjure Cards to Aid <br/> you in battle",
		text: "If you’re ever unable to draw a card from your deck, the powers of Sky will help you by conjuring a card directly from your Prisms, at the cost of one health, giving you new cards to aid you in battle and opening up new avenues of play.",
		video: { url: `${VIDEO_PREFIX}conjure.mp4` }
	},
	decks: {
		subtitle: "Decks",
		title: "Build an Entirely Unique Deck",
		text: "Skyweaver is a singleton game, so you don’t have to worry about collecting multiple copies of the same card! Each card in your deck is entirely unique, making individual card choices more important than ever!",
		video: { url: `${VIDEO_PREFIX}deck-creation.mp4` }
	},
	gameplay: {
		subtitle: "The Arena",
		title: "Game Interface",
		items: [
			{
				title: "Mana",
				text: "Your Mana, which you can use to play cards. At the start of each of your turns, you’ll gain a maximum mana and refill your mana to full.",
				contentImage: { url: `${IMAGE_PREFIX}gameplay/mana.jpg` }
			},
			{
				title: "Graveyard",
				text: "Player's Graveyards. All destroyed Units and Spells the Heroes cast go here. You can view a list of all your dead cards by clicking on your Graveyard (at the bottom) or pressing G.",
				contentImage: { url: `${IMAGE_PREFIX}gameplay/graveyard.jpg` }

			},
			{
				title: "Hand",
				text: "The cards you have available to play. Cards that you have the mana to play will have a green glow, showing that they can currently be played. You can hold up to nine cards in hand. If you draw more than that, your left-most card in hand will be returned to your deck.  ",
				contentImage: { url: `${IMAGE_PREFIX}gameplay/hand.jpg` }
			},
			// {
			// 	title: "Units",
			// 	text: "The Units you have in play. Units that can attack will glow green. When a Unit is first played, it will be ‘sleeping’ and need a turn to get ‘ready’ to attack. You can have up to six Units in play at once.",
			// 	contentImage: { url: `${IMAGE_PREFIX}GameplayPlaceholder.jpg` }
			// },
			// contentImage: { url: `${IMAGE_PREFIX}gameplay/attached-spell.jpg` }
			{
				title: "Heroes",
				text: "Player's Heroes. Each has attack power (left value) and health (right value), and they can attack directly and take damage. Heroes do not counterattack, unlike Units. If your Hero ever reaches zero health, you are defeated.",
				contentImage: { url: `${IMAGE_PREFIX}gameplay/heroes.jpg` }
			},
			// {
			// 	title: "Enemy Hero",
			// 	text: "The enemy Hero. If you reduce them to zero health, you’ll claim victory!",
			// 	contentImage: { url: `${IMAGE_PREFIX}GameplayPlaceholder.jpg` }
			// },

			{
				title: "Decks",
				text: "Player's decks. You’ll draw a card from your pile each turn, and your opponent will do the same. You can click on your deck at any time to see what cards are still left in it (or use the D hotkey).",
				contentImage: { url: `${IMAGE_PREFIX}gameplay/decks.jpg` }
			},
			{
				title: "Options",
				text: "Adjust volume, graphics, and other game settings.",
				contentImage: { url: `${IMAGE_PREFIX}gameplay/options.jpg` }
			},
			{
				title: "End turn Button",
				text: "Your end turn button, used to pass turn to the opponent when you’re done. It will also glow when you have no possible actions left available in a turn, letting you know it's safe to end your turn.",
				contentImage: { url: `${IMAGE_PREFIX}gameplay/end-turn.jpg` }
			},
			{
				title: "Attached Spell",
				text: "Units and Heroes can have attached Spells. You can click and drag an attached Spell off a Unit or Hero to cast it, provided you have the mana available.",
				contentImage: { url: `${IMAGE_PREFIX}gameplay/attached-spell.jpg` }
			},
		]
	}
}