import React from "react"
import styled from "styled-components"

import { P, H2 } from "components/Typography"

export const Container = ({
	title, text, children, prism
}) => {
	return (
		<StyledContainer>
			<Background>
				<picture>
					<source media="(max-width: 750px)" srcset="/images/how-to-play/BgArenaMobile.jpg" />
					<source media="(min-width: 751px)" srcset="/images/how-to-play/BgArenaDesktop.jpg" />
					<img src="/images/how-to-play/BgArenaDesktop.jpg" alt="" loading="lazy" />
				</picture>
			</Background>

			<TextBlock>
				{title && <H2 color="white" style={{
					fontSize: "2.5rem",
					marginBottom: "0.5rem"
				}}>{title}</H2>}

				{prism && (
					<div className="prism">
						<img alt="" loading="lazy" className="prism-icon" src={`/images/prisms/${prism.icon}.png`} />
						<P style={{ marginBottom: 0, textTransform: "uppercase", fontSize: "15px", fontWeight: "bold" }} color="white">{prism.text} Skyweaver</P>
					</div>
				)}

				{text && <P>{text}</P>}
			</TextBlock>
			{children}
		</StyledContainer>
	)
}

const HeroBlock = ({ title, text, contentImage, prism }) => {
	return (
		<Container title={title} text={text} prism={prism}>
			<ImageBlock>
				<img height="1092" width="648" loading="lazy" src={contentImage?.url} alt={title} />
			</ImageBlock>
		</Container>
	)
}

export default HeroBlock

const StyledContainer = styled.div`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding: 2rem;
	padding-bottom: 0;
	box-sizing: border-box;
`

const TextBlock = styled.div`
	width: calc(100% - 18rem);
	padding-right: 4rem;
	z-index: 2;

	.prism-icon {
		width: 30px;
		margin-right: 10px;
	}

	.prism {
		margin-bottom: 0.5rem;
		display: flex;
		align-items: center;
	}

	@media (max-width: 750px) {
		width: 100%; padding-right: 0;
	}
`

const ImageBlock = styled.div`
	width: 18rem;
	height: 23rem;
	box-sizing: border-box;
	z-index: 2;
	overflow: hidden;

	img {
		width: 100%;
		box-sizing: border-box;
	}

	@media (max-width: 750px) {
		margin: auto;
	}
`

const Background = styled.div`
	position: absolute;
	top: 0;
	right: 0;


	&, img {
		height: 100%;
		width: 100%;
	}

	img {
		object-fit: cover;
	}
`