import React from "react"
import styled from "styled-components"

import { P } from "components/Typography"

export const Container = ({
	title, text, children
}) => {
	return (
		<StyledContainer>
			<Background>
				<picture>
					<source media="(max-width: 750px)" srcset="/images/how-to-play/BgArenaMobile.jpg" />
					<source media="(min-width: 751px)" srcset="/images/how-to-play/BgArenaDesktop.jpg" />
					<img src="/images/how-to-play/BgArenaDesktop.jpg" alt="" loading="lazy" />
				</picture>
			</Background>

			<TextBlock>
				{title && <P style={{
					fontWeight: "bold",
					fontSize: "1.6rem",
					textTransform: "capitalize",
					marginBottom: "0.2rem"
				}}>{title} Prism</P>}
				{text && <P>{text}</P>}
			</TextBlock>
			{children}
		</StyledContainer>
	)
}

const PrismBlock = ({ title, text, contentImage }) => {
	return (
		<Container title={title} text={text}>
			<ImageBlock>
				<img height="576" width="576" loading="lazy" src={contentImage?.url} alt={title} />
			</ImageBlock>
		</Container>
	)
}

export default PrismBlock

const StyledContainer = styled.div`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	width: 100%;
	padding: 2rem;
	box-sizing: border-box;
`

const TextBlock = styled.div`
	width: calc(100% - 15rem);
	padding-right: 2rem;
	z-index: 2;

	@media (max-width: 750px) {
		width: 100%; padding-right: 0;
	}
`

const ImageBlock = styled.div`
	width: 15rem;
	box-sizing: border-box;
	z-index: 2;

	img {
		width: 100%;
		box-sizing: border-box;
	}

	@media (max-width: 750px) {
		margin: auto;
	}
`

const Background = styled.div`
	position: absolute;
	top: 0;
	right: 0;


	&, img {
		height: 100%;
		width: 100%;
	}

	img {
		object-fit: cover;
	}
`