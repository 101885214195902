import React, { useState } from "react"
import styled from "styled-components"

import Nav from "./Nav"
import BackgroundContainer from "./BackgroundContainer"
import CardHotspots from "./CardHotspots"
import Carousel, { GenericVideoBlock } from "./Carousel"
import PrismBlock from "./PrismBlock"
import HeroBlock from "./HeroBlock"

export const GROUP_ORDER = ["cards", "battling", "spells", "traits", "traitsSpells", "effects", "enchants", "prisms", "heroes", "conjureCards", "decks", "gameplay"]

const GroupContainer = (props) => {
	const {
		cards, battling, spells, traits, traitsSpells, effects, enchants, heroes, prisms, conjureCards, decks, gameplay
	} = props

	const [selectedNavItem, setSelectedNavItem] = useState(false)

	return (
		<Parent>
			<Container>
				<Nav selectedNavItem={selectedNavItem} groupOrder={GROUP_ORDER.map(item => props[item] ? props[item] : false)} />
				<div />

				<Groups>
					<BackgroundContainer setSelectedNavItem={setSelectedNavItem} {...cards}>
						<CardHotspots {...cards} />
					</BackgroundContainer>
					<BackgroundContainer setSelectedNavItem={setSelectedNavItem} {...battling} />
					<BackgroundContainer setSelectedNavItem={setSelectedNavItem} {...spells} />
					<BackgroundContainer setSelectedNavItem={setSelectedNavItem} {...traits}>
						<Carousel  {...traits}
							type={"effects"}
							content={traits.items.map((item, i) => <GenericVideoBlock key={i} video={item.video} text={item.text} />)} />

					</BackgroundContainer>
					<BackgroundContainer setSelectedNavItem={setSelectedNavItem} {...traitsSpells} />
					<BackgroundContainer setSelectedNavItem={setSelectedNavItem} {...effects}>
						<Carousel  {...effects}
							type={"effects"}
							content={effects.items.map((item, i) => <GenericVideoBlock key={i} video={item.video} text={item.text} />)} />

					</BackgroundContainer>
					<BackgroundContainer setSelectedNavItem={setSelectedNavItem} {...enchants}>
						<Carousel  {...enchants}
							type={"enchants"}
							content={enchants.items.map((item, i) => <GenericVideoBlock key={i} {...item} />)} />
					</BackgroundContainer>
					<BackgroundContainer setSelectedNavItem={setSelectedNavItem} {...prisms}>
						<Carousel  {...prisms}
							type={"effects"}
							content={prisms.items.map((item, i) => <PrismBlock key={i} {...item} />)} />
					</BackgroundContainer>

					<BackgroundContainer setSelectedNavItem={setSelectedNavItem} {...heroes}>
						<Carousel  {...heroes}
							type={"enchants"}
							content={heroes.items.map((item, i) => <HeroBlock key={i} {...item} />)} />
					</BackgroundContainer>

					<BackgroundContainer setSelectedNavItem={setSelectedNavItem} {...conjureCards} />
					<BackgroundContainer setSelectedNavItem={setSelectedNavItem} {...decks} />
					<BackgroundContainer setSelectedNavItem={setSelectedNavItem} {...gameplay}>
						<Carousel  {...gameplay}
							type="gameplay"
							content={gameplay.items.map((item, i) => <GenericVideoBlock key={i} text={item.text} contentImage={item.contentImage} />)} />
					</BackgroundContainer>
				</Groups>

			</Container>
		</Parent>

	)
}

export default GroupContainer


const Parent = styled.div`
	width: 100%;
	position: relative;
`
const Groups = styled.div`
	position: relative;
	padding-top: 2rem;
	padding-right: 7rem;
	width: calc(100% - 20rem);
	max-width: 1050px;
	overflow: hidden;
	margin: auto;

	@media (max-width: 750px) {
		width: 100%;
		padding-right: 0;
	}
`
const Container = styled.div`
padding-bottom: 4rem;
	display: flex; flex-wrap: wrap;
	width: 100%;
	max-width: 1400px;
	margin: auto;
`