import React, { useState } from "react"
import styled from "styled-components"

import { Sub, P, H2 } from "components/Typography"

const StyledCard = styled.div`
	position: relative;
	width: 50%;

	padding: 2rem;

	figure {
		width: 100%;
		height: 0;
		padding-bottom: 120%;
		position: relative;
		margin-bottom: 6rem;

		.sub {
			position: absolute;
			bottom: -4rem;
			width: 100%;
			text-align: center;
		}
	}
	img {
		position: absolute;
		top:0;left:0;
		height: 100%;
		width: 100%;
		object-fit: contain;
	}

	@media (max-width: 750px) {
		width: 100%;
		border-bottom: 1px solid var(--darkBorder);
	}
`

const Point = ({ position, title, text }) => {
	const [active, setActive] = useState(false)

	if (!position) return null

	return (
		<>
			<StyledText
				className={active && "active"}
				style={{
					left: position[0] + "%",
					top: position[1] + "%"
				}}
			>
				<H2 color="white" style={{
					fontSize: "1.4rem",
					marginBottom: "0.5rem"
				}}>{title}</H2>
				<P>{text}</P>
			</StyledText>
			<StyledPoint
				onMouseEnter={() => {
					setActive(true)
				}}
				onMouseLeave={() => {
					setActive(false)
				}}
				style={{
					left: position[0] + "%",
					top: position[1] + "%"
				}}>
				<div className="point-container">
					<img height="1011" width="659" className="hover" src="/images/how-to-play/PointHover.png" alt="" />
					<img height="1011" width="659" className="active" src="/images/how-to-play/PointDefault.png" alt="" />
				</div>

			</StyledPoint>
		</>
	)
}

const Card = ({
	title, image, text, points
}) => {
	return (
		<StyledCard>
			<figure>
				<img alt={title} loading="lazy" src={image.url} />
				{points?.map((point, i) => <Point {...point} key={i} />)}

				<Sub className="sub">Hover on parts of the image to learn more</Sub>

			</figure>

			<div>
				<H2 style={{ color: "white", fontSize: "1.8rem" }}>{title}</H2>
				<P>{text}</P>
			</div>
		</StyledCard>
	)
}

const CardHotspots = ({ items }) => {
	return (
		<Section>
			{items?.map((item, i) => <Card {...item} key={i} />)}

			<CosmeticLine />
		</Section>
	)
}

export default CardHotspots


const CosmeticLine = styled.div`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	height: 100%;
	width: 1px;
	background:linear-gradient(0deg, #4D3C7B 0%, rgba(0,0,0,0) 100%);
	pointer-events: none;

	@media (max-width: 750px) {
		display: none;
	}
`
const Section = styled.div`
	position: relative;
	display: flex;
	flex-wrap: wrap
`

const StyledText = styled.div`
	position: absolute;
	border: 1px solid #B7FFFF;
	background-color:var(--bgLight);
	padding: 1rem;
	max-width: 20rem;
	width: 100%;
	box-shadow: 0px 0px 15px rgba(82, 193, 255, 0.1);
	z-index: 4;
	transform: translate(3%, 50px);
	pointer-events: none;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s ease-out opacity, 0.3s ease-out visibility;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	p {
		margin-bottom: 0;
		font-size: 14px;
	}

	@media (max-width: 750px) {
		top: 50% !important;
		left: 50% !important;
		transform: translate(-50%,-50%);
	}
`

const StyledPoint = styled.div`
	position: absolute;
	width: 10%;
	height: 10%;

	img {
		width: 100%; text-align: center; margin: auto;
		position: absolute;
		top: 50%;left:50%;
		transform: translate(-50%,-50%);
		transition: 0.3s ease-out opacity;
	}

	.hover {width:120%; opacity: 0}
	.active {width: 200%; height:200%;}

	&:hover .hover {opacity: 1;}
	&:hover .active {opacity: 0;}

	.point-container {
		position: relative;
		text-align: center;
		width: 100%;
		height: 100%;
	}

`