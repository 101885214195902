import React from "react"
import styled from "styled-components"

import { MedMaxWidth } from "components/Layouts/MaxWidth"
import { H2, P } from "components/Typography"

const StyledLanding = styled.section`
    position: relative;
    width: 100%;
    padding-top: 24rem;
		padding-bottom: 4rem;
    margin-bottom: 4rem;
    overflow: hidden;
		text-align: center;

		p {
			margin-bottom: 1rem;
		}

    @media (max-width: 768px) {
        padding-top: 14rem;
        padding-left: 0rem;
        padding-right: 0rem;
				overflow: visible;

				.text-group {
					transform: translateY(7rem);
				}
    }
`

const BackgroundVideo = styled.video`
		position: absolute;
		top:0;left:0;
		height: 100%;
		width: 100%;
		object-fit: cover;
		object-position: top;
		opacity: 0.9;
`

const Gradient = styled.div`
    position: absolute;
    top:0;left:0;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(12, 6, 30, 0.42) 0%, #0C061E 100%)
`


const Landing = ({
	title, text, video
}) => {

	return (
		<StyledLanding>

			<BackgroundVideo poster={video.placeholder} preload="meta" playsInline autoPlay muted loop loading="lazy" src={video.url} />
			<Gradient />

			<MedMaxWidth className="text-group">
				<H2 color="white">{title}</H2>
				<P><span dangerouslySetInnerHTML={{ __html: text }} /></P>
			</MedMaxWidth>
		</StyledLanding>
	)
}

export default Landing