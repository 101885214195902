import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useInView } from 'react-intersection-observer';
import animateScrollTo from "animated-scroll-to";

import { P } from "components/Typography"
import { UP, useScroll } from "utils/useScroll";

const StyledBackToTop = styled.button`
	position: fixed;
	bottom: 2rem;
	left: 2rem;
	padding: 0.5rem 0.65rem;
	border-radius: 2px;
	background-color: var(--bgLight);
	border: 1px solid var(--darkBorder);
	z-index: 49;
	transform: ${props => props.inView && props.direction === UP ? "translateY(0%)" : "translateY(200%)"};
	transition: 0.3s ease-out transform;
	display: none;

	@media (max-width: 750px) {
		display: block;
	}
`

const BackToTop = ({ inView }) => {
	const { direction } = useScroll()

	const onClick = () => {
		const target = document.getElementById('how-to-play-nav')
		if (target) {
			animateScrollTo(target, {
				verticalOffset: -70,
				minDuration: 250,
				maxDuration: 1000
			})
		}
	}

	return (
		<StyledBackToTop direction={direction} onClick={onClick} inView={inView}>
			<P style={{ marginBottom: 0 }}> ↑ Back to top</P>
		</StyledBackToTop>
	)
}




const EndOfScrollCheck = ({ setReachedEnd }) => {
	const { ref, inView } = useInView({
		threshold: 0,
	});

	useEffect(() => {
		setReachedEnd(inView)
	}, [inView, setReachedEnd])

	return (
		<ReachedEndCheck ref={ref} />
	)
}

const Button = ({ selectedNavItem, subtitle }) => {

	const click = () => {
		const target = document.getElementById("play-" + subtitle.toLowerCase())
		if (target) {
			animateScrollTo(target, {
				verticalOffset: -120,
				minDuration: 250,
				maxDuration: 1000
			})
		}
	}

	return (
		<StyledButton onClick={click}>
			<P color={selectedNavItem === subtitle.toLowerCase() ? "white" : "var(--lilac)"}>{subtitle}</P>
		</StyledButton>
	)
}

const Nav = ({
	selectedNavItem,
	groupOrder
}) => {
	const [reachedEnd, setReachedEnd] = useState(false)

	const { ref, inView } = useInView({
		threshold: 0,
	});

	return (
		<>
			<Background reachedEnd={reachedEnd} inView={inView}>
				<img alt="" loading="lazy" src={"/images/how-to-play/BackgroundEffect.jpg"} />
			</Background>
			<BackgroundCheck ref={ref} />
			<Container id="how-to-play-nav">
				<StyledNav>
					{groupOrder.map((item) => <Button {...item} selectedNavItem={selectedNavItem} key={item.subtitle} />)}
				</StyledNav>
			</Container>
			<EndOfScrollCheck setReachedEnd={setReachedEnd} />
			<BackToTop inView={inView} />
		</>
	)
}

export default Nav

const Background = styled.div`
	width: 98.5vw;
	height: 100vh;
	position: ${props => props.inView ? "fixed" : "absolute"};
	// top: ${props => props.inView ? "0px" : "200px"};
	top: 200px;
	transform: ${props => props.inView ? "translateY(-200px)" : "translateY(0px)"};
	opacity: ${props => props.reachedEnd ? "0" : "1"};
	transition: 0.3s ease-out opacity;
	left: 0;
	pointer-events: none;
	overflow: hidden;

	img {
		width: 100%;
		height: 101%;
		object-fit: cover;
	}

	@media (max-width: 750px) {
		display: none;
	}
`

const BackgroundCheck = styled.div`
	position: absolute;
	top: calc(100vh + 200px); left:0;
	z-index: 10;
	width: 10px;
	height: calc(100% - 100vh);
	pointer-events: none;

	
`
const Container = styled.div`
	top:6rem;
	position: sticky; 
	width: 15rem;
	align-self: flex-start;
	padding: 2rem;
	padding-bottom: 8rem;

	@media (max-width: 750px) {
		width: 100%;
		position: relative;
		top: 0;
		padding-bottom: 2rem;
	}
`

const StyledNav = styled.nav`
background-color: var(--bgLight);
border: 1px solid var(--darkBorder);
width: 12rem;

@media (max-width: 750px) {
	width: 100%;
	position: relative;
	top: 0;
}
`

const StyledButton = styled.div`
	width: 100%;
	text-transform: uppercase;
	padding:  0.6rem 1rem;

	border-bottom: 1px solid var(--darkBorder);
	margin-bottom: -1px;
	cursor: pointer;
	
	p {
		margin-bottom: 0;
		font-size: 14px;
	}
`

const ReachedEndCheck = styled.div`
position: absolute;
bottom: -400px;
left: 0;
width: 2px;
height: 200px;
pointer-events: none;

@media (max-width: 750px) {
	display: none;
}
`