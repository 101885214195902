import React from "react";

import "css/main.css";
import "css/fonts.css";

import Seo from "components/SeoHead";
import HowToPlay from "components/HowToPlay"

// title, content, footer
import { data } from "data/how-to-play"

function HowToPlayPage() {
	return (
		<>
			<Seo />
			<HowToPlay {...data} />
		</>
	)
}

export default HowToPlayPage