import React from "react"
import Layout from "components/Blog/Layout"
import { MainContentMaxWidth } from "components/Layouts/MaxWidth";
import Landing from "./Landing"
import GroupContainer from "./GroupContainer"
import Block from "components/CallToAction/Block"

const HowToPlay = (props) => {
	const {
		landing
	} = props

	return (
		<Layout enforceBackground>
			<Landing {...landing} />
			<GroupContainer {...props} />
			<MainContentMaxWidth>
				<Block title={"GET STARTED TO PLAY"} text="Create your account to start playing" buttonLink={"/get-started"} buttonTitle="STEPS TO GET STARTED
" />
			</MainContentMaxWidth>
		</Layout>
	)
}

export default HowToPlay